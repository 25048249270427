<template>
<div
  @click="$emit('toggle')"
  class="thread-item"
  :class="{ 'thread-item--opened': opened }"
>
  <div class="dropdown-wrapper">
    <dropdownIcon class="dropdown"/>
  </div>
  <div class="content-wrapper">
    <div class="header">
      <div class="left">
        <div class="property property--subject" v-html="item.subject">
        </div>
        <div class="property property--from">
          From: {{ item.email_from }}
        </div>
        <div class="property property--to">
          to {{ item.email_to }}
        </div>
      </div>
      <div class="right">
        <div class="property property--date">
          {{ item.date }}
        </div>
        <div class="actions">
          <template v-if="item.actions">
            <template v-for="btn in item.actions">
              <div
                v-if="buttons[btn]"
                :key="buttons[btn].id"
                class="actions__item-wrapper"
                :title="buttons[btn].title"
                :name="`thread__${item.id}__action-item__${buttons[btn].id}`"
                @click.stop.prevent
              >
                <component
                  :is="buttons[btn].component"
                  v-bind="buttons[btn].componentConfig"
                  @click="buttons[btn].action"
                  class="actions__item"
                  :class="['actions__item--' + buttons[btn].type, buttons[btn].class, {
                    'disabled': item.disabled,
                  }]"
                />
                <component
                  v-if="buttons[btn].modal"
                  :is="buttons[btn].modal"
                  :type="buttons[btn].type"
                  :to="`thread__${item.id}__action-item__${buttons[btn].id}`"
                />
              </div>
            </template>
          </template>
        </div>
      </div>
    </div>
    <div v-if="opened" class="description">
      <div class="property property--description"
        v-html="item.html_body"
      ></div>
    </div>
  </div>
</div>
</template>

<script>
import { buttons } from "@/components/dashboard-components/checklist/utils";

const dropdownIcon = () => import("@/assets/icons/dashboard/dropdown-thin.svg");

export default {
  name: 'thread-item',
  components: {
    dropdownIcon,
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
    opened: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      buttons,
    }
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/styles/functions.scss';

.thread-item {
  display: flex;
  align-items: flex-start;
  width: 100%;
  cursor: pointer;
  .dropdown-wrapper {
    padding: 0 11px;
    cursor: pointer;
    .dropdown {
      transition: transform .3s;
      transform: rotate(90deg);
    }
  }
  .content-wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
    flex-grow: 1;
    .header {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      width: 100%;
      .left {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 4px;
      }
      .right {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        gap: 10px;
        .actions {
          display: inline-flex;
          align-items: center;
          gap: 12px;
          &__item {
            cursor: pointer;
            transition: filter .3s;
            &--check {
              height: 1.5rem;
              margin-bottom: 4px;
              &.disabled {
                fill: #AFAFAF;
                cursor: default;
              }
            }
            &.hover {
              &:hover {
                filter: brightness(1.2);
              }
            }
          }
        }
      }
    }
  }
  .property {
    &--subject {
      font-size: 1rem;
      font-weight: 700;
      color: var(--theme-color-main);
      // display: flex;
      align-items: center;
      gap: 10px;
    }
    &--from, &--to {
      font-size: toRem(12px);
      font-weight: 700;
    }
    &--from {
      color: #A7A7A7;
    }
    &--to {
      color: #000000;
    }
    &--description {
      margin-top: 9px;
      color: rgba(40, 40, 40, 0.7);
      font-size: toRem(14px);
      line-height: toRem(24px);
      font-weight: 500;
    }
    &--date {
      color: rgba(0, 0, 0, 0.6);
      font-size: toRem(14px);
      font-weight: 600;
    }
  }
  &--opened {
    .dropdown-wrapper {
      .dropdown {
        // transition: transform .3s;
        transform: rotate(0);
      }
    }
  }
}
</style>